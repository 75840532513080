
import { Component, Vue } from "vue-property-decorator";
import { AuthInterceptor } from "@/config";
import { UsersApi } from "@fundraisingbureauet/admin-typescript-client/apis/UsersApi";

@Component({
  components: {},
})
export default class Login extends Vue {
  valid = false;
  username = "";
  password = "";

  performLogin() {
    new UsersApi(AuthInterceptor.Instance)
      .login_1({ login: { username: this.username, password: this.password } })
      .subscribe(
        (value) => {
          const authorization = value.token;

          //  Capture token
          if (authorization) {
            localStorage.setItem("token", authorization);
            this.$router.push({ name: "Merchants" });
          } else {
            alert("Login failed");
          }
        },
        (error) => {
          //  Log
          console.error("Could not login");
          console.error(error);

          alert("Login rejected");
        }
      );
  }
}
